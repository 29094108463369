import { makeStyles } from '@material-ui/core'
import React from 'react'
import LogoIcon from '../assets/images/logo.png'

const useStyles = makeStyles((theme) => ({
  logoStyle: {
    width: '49px',
    height: '26px',
    padding: '4px 0px 3.9px 10px',
    [theme.breakpoints.up('md')]: { width: '99px', height: '53px', padding: '5px 0px 6.6px 24px' },
    [theme.breakpoints.up('lg')]: { width: '151px', height: '81px', padding: '7px 0px 10px 72px' }
  }
}))
export default () => {
  const classes = useStyles()
  return <img src={LogoIcon} alt="logo" className={classes.logoStyle} />
}