import React from 'react'
import { Hidden, makeStyles } from '@material-ui/core'
import CustomLabelledText from './CustomLabelledText'
import ContactForm from './Forms/ContactForm'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: { paddingTop: '25px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '56px' }
  },
  subTitle: {
    paddingTop: '4px',
    [theme.breakpoints.up('md')]: { paddingTop: '8px' },
    [theme.breakpoints.up('lg')]: { padding: '24px 0px 20px 0px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CustomLabelledText text="Let's Get in Touch" />
      <div className={classes.subTitle}>
        <Hidden mdDown>
          <CustomLabelledText text="Have any queries? We are here, let's get in touch" subtitle />
        </Hidden>
        <Hidden lgUp>
          <CustomLabelledText text="Have any queries? Let's get in touch" subtitle />
        </Hidden>
      </div>
      <ContactForm />
    </div>
  )
}