import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import CustomLabelledText from './CustomLabelledText'
import Facebook from './Images/Facebook'
import Instagram from './Images/Instagram'
const useStyles = makeStyles((theme) => ({
  details: {
    backgroundColor: theme.palette.primary.main, padding: '20px 0px',
    [theme.breakpoints.up('md')]: { padding: '25px 0px' },
    [theme.breakpoints.up('lg')]: { padding: '56px 0px' }
  }
}))
export default ({ text }) => {
  const classes = useStyles()
  return (
    <Box className={classes.details}>
      <CustomLabelledText text="Social Media" />
      <Box display="flex">
        <a href="https://www.facebook.com/Mahasukh-TEA-104886177776795/">
        <Facebook />
        </a>
        <a href="https://www.instagram.com/mahasukhtea/">
        <Instagram />
        </a>
      </Box>
    </Box>
  )
}
