import React from 'react'
import Theme from './components/Theme'
import MahaSukhTea from './pages/MahaSukhTea'
import { makeStyles, ThemeProvider } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
   maxWidth:"1920px",
   display:"block",
   marginLeft:"auto",
   marginRight:"auto"
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ThemeProvider theme={Theme}>
        <MahaSukhTea />
      </ThemeProvider>
    </div>
  )
}
