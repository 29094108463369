import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  headingStyle: {
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 'bold',
    lineHeight: 1.41,
    color: theme.palette.primary.light,
    fontSize: '12px',
    paddingTop: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: { fontSize: '18px', paddingTop: '32px', textAlign: 'left' },
    [theme.breakpoints.up('lg')]: { fontSize: '34px', paddingTop: '60px' }
  }
}))
export default ({ heading, className }) => {
  const classes = useStyles()
  return <Typography className={`${classes.headingStyle} ${className}`}>{heading}</Typography>
}
