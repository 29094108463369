import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  descriptionStyle: {
    fontFamily: theme.typography.fontFamily,
    lineHeight: '16px',
    color: theme.palette.secondary.main,
    fontSize: '10px',
    textAlign: 'center',
    paddingTop: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px', textAlign: 'left', lineHeight: '30px', paddingTop: '16px'
    },
    [theme.breakpoints.up('lg')]: { fontSize: '22px', lineHeight: '38px', paddingTop: '24px' }
  },
  p0: { padding: '0px' }
}))

export default ({ description, className, p0 }) => {
  const classes = useStyles()
  const style = p0 ? `${classes.descriptionStyle} ${classes.p0} ${className}` : `${classes.descriptionStyle}  ${className}`
  return <Typography className={style}>{description}</Typography>
}
