import { Box, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import CustomButton from './CustomButton'
import CustomHeading from './CustomHeading'
import CustomSubtitle from './CustomSubtitle'
const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    paddingTop: '40px', display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: { paddingTop: '48px', justifyContent: 'left' },
    [theme.breakpoints.up('lg')]: { paddingTop: '64px' }
  }
}))
export default (props) => {
  const { heading1, heading2, description1, description2 } = props
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={6}>
      <CustomHeading heading={heading1} />
      <CustomSubtitle description={description1} />
      <CustomHeading heading={heading2} />
      <CustomSubtitle description={description2} />
      <Box className={classes.buttonStyle}>
        <CustomButton text="Contact Now For Your Tea Bag" contact />
      </Box>
    </Grid>
  )
}