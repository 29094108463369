import React from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.dark,
    width: '14.6px',
    height: '14.6px',
    paddingRight: '6.6px',
    [theme.breakpoints.up('lg')]: {
      width: '19.6px',
      height: '19.6px',
      paddingRight: '8.4px',
      paddingTop: '2px'
    }
  },
  contactError: {
    color: theme.palette.error.main,
  }
}))
export default ({ contactError }) => {
  const classes = useStyles()
  const renderClass = contactError ? `${classes.errorIcon} ${classes.contactError}` : classes.errorIcon
  return <ErrorOutlineIcon className={renderClass} />
}
