import React from 'react'
import { makeStyles } from '@material-ui/core'
import Distributor from '../../assets/images/distributor.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '253.8px',
    height: '151.4px',
    padding: '26px 0px 16.6px 0px',
    [theme.breakpoints.up('md')]: {
      width: '600px', height: '358px', padding: '48px 0px 0px 0px'
    },
    [theme.breakpoints.up('lg')]: { width: '100%', height: '392.5px', margin: '219px 0px 0px 73px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Distributor} alt="Distributor" className={classes.imageStyle} />
}

