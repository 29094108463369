import React from 'react'
import { makeStyles } from '@material-ui/core'
import Logo from '../../assets/images/franchiseLogo.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '55px',
    height: '42px',
    [theme.breakpoints.up('md')]: {
      width: '75px',
      height: '58px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '99px',
      height: '76px'
    }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Logo} alt="leaf" className={classes.imageStyle} />
}