import { Grid, Hidden } from '@material-ui/core'
import React, { useState } from 'react'
import { ERROR_MESSAGES } from '../../constants/ErrorMessages'
import { sendQuery } from '../../services/QueryService'
import CustomTextField from '../CustomTextField'
import GetInTouchButton from '../Images/GetInTouchButton'
import TextArea from '../TextArea'
import ThankYouDialog from '../ThankYouDialog'
export default () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState([])
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = () => {
    let tempError = []
    tempError.name = !name ? ERROR_MESSAGES.NAME : ""
    tempError.email = !email ? ERROR_MESSAGES.EMAIL : ""
    tempError.validEmail = !/(.+)@(.+){2,}\.(.+){2,}/.test(email) ? ERROR_MESSAGES.VALID_EMAIL : ""
    tempError.phone = !phone ? ERROR_MESSAGES.PHONE : ""
    tempError.message = !message ? ERROR_MESSAGES.MESSAGE : ""
    setError(tempError)
    if (!tempError.name && !tempError.email && !tempError.phone && !tempError.message && !tempError.validEmail ) {
      sendQuery({ name, email, phone, message }).then(response => {if(response.status === 201){
        setName("")
        setEmail("")
        setPhone("")
        setMessage("")
        setError("")
        setOpen(true)
      }}).catch(error => console.log(error))
    }
  }
  return (
    <Grid container>
      {open ? <ThankYouDialog handleClose={handleClose} open={open} name={name} />:null}
      <Grid item xs={11}>
        <CustomTextField label="Enter Your Name" value={name} handleChange={setName} errorMessage={error && error.name ? error.name : ""} contactError />
      </Grid>
      <Hidden smDown>
        <Grid item md={5}>
          <CustomTextField label="Enter Your Email" value={email} handleChange={setEmail} errorMessage={(error && error.email ? error.email : "") || (error && error.validEmail ?error.validEmail :"") } contactError />
        </Grid>
      </Hidden>
      <Grid item md={1} />
      <Grid item xs={11} md={5}>
        <CustomTextField label="Enter Your Phone" value={phone} handleChange={setPhone} errorMessage={error && error.phone ? error.phone : ""} contactError />
      </Grid>
      <Grid item xs={11}>
        <TextArea label="Message" value={message} handleChange={setMessage} errorMessage={error && error.message ? error.message : ""} contactError />
      </Grid>
      <Grid item xs={11} md={4}>
        <GetInTouchButton handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  )
}