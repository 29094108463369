import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import CustomSubtitle from './CustomSubtitle'
import Franchise from './Franchise'
import FranchiseForm from './Forms/FranchiseForm'
const useStyles = makeStyles((theme) => ({
  subTitle: {
    textAlign: 'center', padding: '0px',
    [theme.breakpoints.up('lg')]: { textAlign: 'left', lineHeight: '36px' }
  },
  subTitle2: {
    fontWeight: 'bold', padding: '10px 0px',
    [theme.breakpoints.up('md')]: { padding: '12px 0px 20px 0px' },
    [theme.breakpoints.up('lg')]: { padding: '16px 0px 40.7px 0px' }
  },
  root: {
    marginTop: '24px',
    [theme.breakpoints.up('md')]: { marginTop: '44px' },
    [theme.breakpoints.up('lg')]: { marginTop: '72.7px' }
  },
}))

export default ({ heading, description }) => {
  const classes = useStyles()
  return (
    <Grid item lg={6}>
      <Franchise heading={heading} />
      <CustomSubtitle description="One of the successful retailers for  Mahasukh Tea"
        className={`${classes.subTitle2} ${classes.subTitle}`} />
      <CustomSubtitle description={description} className={classes.subTitle} />
      <FranchiseForm />
    </Grid>
  )
}
