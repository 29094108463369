import React from 'react'
import { Box } from '@material-ui/core'
import TeaGradeCardContent from './TeaGradeCardContent'
import { teaGradeDataTablet1, teaGradeDataTablet2 } from '../shared/data'
export default () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" paddingTop="64px">
        {teaGradeDataTablet1.map((item, index) => (
          <Box key={index}>
            <TeaGradeCardContent
              image={item.image} heading={item.heading} description={item.description} description2={item.description2}
              description3={item.description3} />
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-evenly" paddingTop="46px">
        {teaGradeDataTablet2.map((item, index) => (
          <Box key={index}>
            <TeaGradeCardContent image={item.image} heading={item.heading} description={item.description} description2={item.description2}
              description3={item.description3} />
          </Box>
        ))}
      </Box>
    </>
  )
}