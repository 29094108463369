import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import NavbarList from './NavbarList'
import Logo from './Logo'

const useStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: '#fffced',
    opacity: '0.83',
    display: 'flex'
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.headerStyle}>
      <Box flexGrow={1}>
        <Logo />
      </Box>
      <NavbarList />
    </Box>
  )
}

