import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import Header from '../components/Header'
import Image from '../assets/images/Tea.png'
import BrandTagline from '../components/BrandTagline'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Image})`,
    backgroundSize: '100% 100%',
    height: '245px',
    [theme.breakpoints.up('md')]: { height: '578px' },
    [theme.breakpoints.up('lg')]: { height: '960px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Header />
      <BrandTagline />
    </Box>
  )
}
