import React from 'react'
import { makeStyles } from '@material-ui/core'
import MediumLeaf from '../../assets/images/mediumLeaf.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '116px',
    height: '61px',
    [theme.breakpoints.up('md')]: {
      width: '195.8px',
      height: '112.8px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '187.7px',
      height: '118.3px',
      paddingTop: '8px'
    }
  }
}))
export default () => {
  const classes = useStyles()
  return <img src={MediumLeaf} alt="" className={classes.imageStyle} />
}