import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { contactDetails } from '../shared/data'
import CustomLabelledText from './CustomLabelledText'
import SocialMedia from './SocialMedia'
const useStyles = makeStyles((theme) => ({
  details: {
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: { paddingTop: '25px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '56px' }
  },
  subTitle: {
    paddingTop: '4px',
    [theme.breakpoints.up('md')]: { paddingTop: '8px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '24px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <div>
      {contactDetails.map((item, index) => (
        <Box className={classes.details} key={index}>
          <a key={index} href={item.to} style={{ textDecoration: "none" }}>
            <CustomLabelledText text={item.title} />
            <div className={classes.subTitle}>
              <CustomLabelledText text={item.subtitle1} subtitle />
              <CustomLabelledText text={item.subtitle2} subtitle paddingTop />
            </div>
          </a>
        </Box>
      ))}
      <SocialMedia />
    </div>
  )
}