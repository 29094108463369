import React from 'react'
import HeroSection from '../sections/HeroSection'
import UspSection from '../sections/UspSection'
import TeaSection from '../sections/TeaSection'
import AboutUsSection from '../sections/AboutUsSection'
import TeaGrade from '../sections/TeaGrade'
import DistributorSection from '../sections/DistributorSection'
import FranchiseSection from '../sections/FranchiseSection'
import FooterSection from '../sections/FooterSection'

const MahaSukhTea = () => {
  return (
    <div>
      <HeroSection />
      <UspSection />
      <TeaSection />
      <AboutUsSection />
      <TeaGrade />
      <DistributorSection />
      <FranchiseSection />
      <FooterSection />
    </div>
  )
}

export default MahaSukhTea
