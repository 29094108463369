import React from 'react'
import { makeStyles } from '@material-ui/core'
import Industry from '../../assets/images/Industry.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '59.3px',
    height: '61.9px',
    [theme.breakpoints.up('md')]: { width: '66.1px', height: '69px' },
    [theme.breakpoints.up('lg')]: { width: '114.9px', height: '120px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Industry} alt="industry" className={classes.imageStyle} />
}