import React from 'react'
import { Box, Hidden, IconButton, makeStyles } from '@material-ui/core'
import FullWidthButton from './FullWidthButton'
import Contact from '../assets/images/ContactImage.png'
import GetInTouch from '../assets/images/getInTouch.png'

const useStyle = makeStyles((theme) => ({
  imageStyle: {
    width: '262px',
    height: '62px',
    cursor: 'pointer'
  }
}))
export default ({ text, contact, handleSubmit, disabled }) => {
  const classes = useStyle()
  return (
    <Box>
      <Hidden smDown>
        <FullWidthButton disabled={disabled} text={text} handleSubmit={handleSubmit} />
      </Hidden>
      <Hidden mdUp>
        <IconButton onClick={() => { handleSubmit() }}>
          <img src={contact ? Contact : GetInTouch} alt="pic" className={classes.imageStyle} />
        </IconButton>
      </Hidden>
    </Box>
  )
}