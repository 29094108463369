import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CustomHeading from './CustomHeading'
import CustomImage from './Images/CustomImage'
import CustomSubtitle from './CustomSubtitle'
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '14px',
    paddingTop: '0px',
    textAlign: 'left',
    fontFamily: theme.typography.body2.fontFamily
  },
  subTitle: {
    fontSize: '11px',
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.secondary.main,
    textAlign: 'left', lineHeight: '18px'
  }
}))
export default ({ image, heading, description, description2, description3 }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={6} style={{ marginTop: '32px' }}>
        <CustomImage>{image}</CustomImage>
      </Grid>
      <Grid item xs={6} style={{ marginTop: '32px' }}>
        <CustomHeading heading={heading} className={classes.title} />
        <CustomSubtitle description={description} className={classes.subTitle} />
        <CustomSubtitle description={description2} p0 className={classes.subTitle} />
        <CustomSubtitle description={description3} p0 className={classes.subTitle} />
      </Grid>
    </>
  )
}