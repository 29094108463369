import React from 'react'
import { makeStyles } from '@material-ui/core'
import InstagramImage from '../../assets/images/instagram.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '13.6px',
    height: '12.8px',
    paddingTop: '4.1px',
    [theme.breakpoints.up('md')]: {
      width: '17px',
      height: '16.6px',
      paddingTop: '9px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '28px',
      height: '28px',
      paddingTop: '24px'
    }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={InstagramImage} alt="" className={classes.imageStyle} />
}
