import React from 'react'
import { makeStyles } from '@material-ui/core'
import Price from '../../assets/images/Price.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '43.9px',
    height: '61.1px',
    [theme.breakpoints.up('md')]: { width: '50.3px', height: '70px' },
    [theme.breakpoints.up('lg')]: { width: '86.3px', height: '120px' }
  }
}))
export default () => {
  const classes = useStyles()
  return <img src={Price} alt="price" className={classes.imageStyle} />
}