import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

let Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#004a92',
      light: '#182b3b'
    },
    secondary: {
      main: '#232323',
      dark: '#282828'
    },
    info: {
      main: '#004a92',
      light: '#fffced'
    }
  },
  breakpoints: {
      values: {
        xs: 0,
        sm: 320,
        md: 750,
        lg: 1025,
        xl: 1920
      }
    },
  typography: {
    fontFamily: 'Open Sans',
    body1: {
      fontFamily: 'Merriweather'
    },
    body2: {
      fontFamily: 'Hind Vadodara'
    }
  }
})
Theme = responsiveFontSizes(Theme)

export default Theme
