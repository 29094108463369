import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { uspData } from '../shared/data'
import UspCard from '../components/UspCard'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 10px 0px 0px',
    [theme.breakpoints.up('md')]: {
      padding: '48px 24px 0px 0px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '120px 20px 0px 0px'
    }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} id="why-us">
      {uspData.map((item, index) => (
        <Grid item xs={6} md={3} lg={3} key={index}>
          <UspCard image={item.image} heading={item.heading} description={item.description} />
        </Grid>
      ))}
    </Grid>
  )
}
