import { makeStyles } from '@material-ui/core'
import React from 'react'
import ErrorMessage from './ErrorMessage'
const useStyles = makeStyles((theme) => ({
  textField: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '10px',
    width: '100%',
    height: '30px',
    marginTop: '12px',
    borderRadius: '5px',
    border: '1px solid #707070',
    paddingLeft: '5px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '7px', height: '48px', marginTop: '24px', fontSize: '16px', paddingLeft: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '10px', marginTop: '24px', fontSize: '16px', paddingLeft: '15px'
    }
  },
  errorInput: {
    border: "1px solid #DE6262"
  }
}))
export default ({ label, value, handleChange, errorMessage, contactError }) => {
  const classes = useStyles()
  const renderInputStyle = !errorMessage ? classes.textField : `${classes.textField} ${classes.errorInput}`
  const handleOnChange = (event) => {
    handleChange(event.target.value)
  }
  return <>
    <input type="text" placeholder={label} className={renderInputStyle} value={value} onChange={handleOnChange} />
    <ErrorMessage message={errorMessage} contactError={contactError} />
  </>
}