import React from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import CustomHeading from './CustomHeading'
import CustomSubtitle from './CustomSubtitle'
import CustomImage from './Images/CustomImage'
import DistributorForm from './Forms/DistributorForm'
const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center', padding: '0px 15px 10px 15px',
    [theme.breakpoints.up('md')]: { lineHeight: '52px', padding: '32px 0px 20px 0px' },
    [theme.breakpoints.up('lg')]: { padding: '90px 0px 40.7px 0px', textAlign: 'left' }
  },
  subTitle: {
    textAlign: 'center', padding: '0px 0px 24px 0px',
    [theme.breakpoints.up('lg')]: { textAlign: 'left' }
  }, imageStyle: { [theme.breakpoints.up('md')]: { justifyContent: "center" } }
}))
export default ({ image, description, heading }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Hidden lgUp>
        <Grid item xs={12} md={12} lg={6}> <CustomImage className={classes.imageStyle}> {image} </CustomImage> </Grid>
      </Hidden>
      <Grid item lg={5}>
        <CustomHeading heading={heading} className={classes.title} />
        <CustomSubtitle description={description} className={classes.subTitle} />
        <DistributorForm />
      </Grid>
      <Grid item lg={1} />
      <Hidden mdDown>
        <Grid item xs={12} md={12} lg={6}> <CustomImage className={classes.imageStyle}> {image} </CustomImage> </Grid></Hidden>
    </Grid>
  )
}