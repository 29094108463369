import { makeStyles } from '@material-ui/core'
import React from 'react'
import ErrorMessage from './ErrorMessage'
const useStyles = makeStyles((theme) => ({
  textArea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '10px',
    width: '100%',
    height: '57px',
    marginTop: '12px',
    borderRadius: '5px',
    border: '1px solid #707070',
    paddingLeft: '5px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '7px', height: '81px', marginTop: '24px', fontSize: '16px', paddingLeft: '10px', paddingTop: "21px"
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '10px', marginTop: '24px', fontSize: '16px', paddingLeft: '15px', height: '154px'
    }
  },
  errorInput: {
    border: "1px solid #DE6262"
  }
}))
export default ({ label, value, handleChange, errorMessage, contactError }) => {
  const classes = useStyles()
  const renderInputStyle = !errorMessage ? classes.textArea : `${classes.textArea} ${classes.errorInput}`
  const handleOnChange = ({ target }) => {
    handleChange(target.value)
  }
  return <>
    <textarea value={value} placeholder={label} className={renderInputStyle} onChange={handleOnChange} />
    <ErrorMessage message={errorMessage} contactError={contactError} />
  </>
}