import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import VideoImage from '../../assets/images/Video.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: { justifyContent: 'flex-end' }
  },
  imageStyle: {
    width: '253.8px',
    height: '151.4px',
    padding: '26px 0px 16.6px 0px',
    [theme.breakpoints.up('md')]: { width: '600px', height: '358px' },
    [theme.breakpoints.up('lg')]: { width: '100%', height: '423px', margin: '154px 0px 0px 52px' }
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={12} lg={5} className={classes.root}>
      <img src={VideoImage} alt="Video" className={classes.imageStyle} />
    </Grid>
  )
}