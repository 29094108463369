import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import CustomHeading from './CustomHeading'
import FranchiseLogo from './Images/FranchiseLogo'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: { marginTop: '22px' },
    [theme.breakpoints.up('lg')]: { justifyContent: 'flex-start' }
  },
  title: {
    textAlign: 'center', paddingTop: '13px',
    [theme.breakpoints.up('md')]: { lineHeight: '52px', padding: '0px' },
    [theme.breakpoints.up('lg')]: { padding: '12px 0px 0px 8px', textAlign: 'left' }
  }
}))
export default ({ heading }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <FranchiseLogo />
      <CustomHeading heading={heading} className={classes.title} />
    </Box>
  )
}