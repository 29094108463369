import React from 'react'
import TeaBag from '../../assets/images/bags.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '182px',
    height: '121px',
    [theme.breakpoints.up('md')]: { width: '295px', height: '196px' },
    [theme.breakpoints.up('lg')]: { width: '484px', height: '322px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={TeaBag} alt="tea bag" className={classes.imageStyle} />
}