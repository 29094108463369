import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
const useStyle = makeStyles((theme) => ({
  buttonStyle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    lineHeight: 1.33,
    textAlign: 'left',
    textTransform: 'capitalize',
    fontSize: '10px',
    padding: '8px 14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      padding: '14px 26px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
      padding: '18px 42px',
      borderRadius: '10px'
    }
  },
  disabled: {
    backgroundColor: "#ffffff"
  }
}))
export default ({ text, handleSubmit, disabled }) => {
  const classes = useStyle()
  const checkDisabled = disabled ? `${classes.buttonStyle} ${classes.disabled}` : `${classes.buttonStyle}`
  return (
    <Button variant={disabled ? "disabled" : "contained"} color="primary" className={checkDisabled}
      onClick={() => { handleSubmit() }}
    >
      {text}
    </Button>
  )
}
