import React from 'react'
import {  Dialog,  DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import CustomHeading from './CustomHeading';
import CustomSubtitle from './CustomSubtitle';
const useStyles = makeStyles((theme) => ({
  heading: {padding:"0px"}
}))
export default ({open,handleClose,name}) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle> <CustomHeading heading={`Thank You  ${name} !`} className={classes.heading} /> </DialogTitle>
        <DialogContent>
           <CustomSubtitle description="We will get back to you" p0 /> 
        </DialogContent>
      </Dialog>
  )
}
