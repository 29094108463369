import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import GetInTouchCard from '../components/GetInTouchCard'
import Heading from '../components/Heading'
import DistributorImage from '../components/Images/DistributorImage'
import { distributordescription } from '../shared/data'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '34px 10px',
    [theme.breakpoints.up('md')]: { padding: '48px 24px' },
    [theme.breakpoints.up('lg')]: { padding: '120px 72px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root} id="distributor">
      <Heading text="Become Distributor And Grow With Us" />
      <GetInTouchCard
        image={<DistributorImage />}
        heading="High Profit Margin with  
          diversified product portfolio"
        description={distributordescription}
      />
    </Box>
  )
}
