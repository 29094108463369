import React from 'react'
import { Box, Hidden, makeStyles } from '@material-ui/core'
import CustomHeading from './CustomHeading'
import CustomSubtitle from './CustomSubtitle'
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '13px',
    padding: '0px',
    color: theme.palette.primary.light,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: { fontSize: '28px', fontWeight: 'bold' },
    [theme.breakpoints.up('lg')]: {
      fontSize: '48px', fontFamily: theme.typography.fontFamily, lineHeight: '70px'
    }
  },
  subTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    fontSize: '12px',
    lineHeight: '19px',
    [theme.breakpoints.up('lg')]: { lineHeight: '30px', fontSize: '20px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box>
      <CustomHeading heading="It's Not Just A Tea," className={classes.title} />
      <CustomHeading heading="But A Refreshing Burst" className={classes.title} />
      <Hidden smDown>
        <CustomSubtitle
          description="Every sip is infused with years of experience  and consistent"
          className={classes.subTitle}
        />
        <CustomSubtitle
          description="quality leaving a long lasting taste of  refreshing burst on your"
          className={classes.subTitle}
          p0
        />
        <CustomSubtitle description="palate" className={classes.subTitle} p0 />
      </Hidden>
    </Box>
  )
}
