import React from 'react'
import TeaCup from '../../assets/images/teaCup.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '255px',
    height: '191px',
    [theme.breakpoints.up('md')]: { width: '602px', height: '452px' },
    [theme.breakpoints.up('lg')]: { width: '100%', height: '436px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={TeaCup} alt="teaCup" className={classes.imageStyle} />
}