import React from 'react'
import { Grid } from '@material-ui/core'
import { teaGradeData } from '../shared/data'
import TeaGradeCardContentMobile from './TeaGradeCardContentMobile'

export default () => {
  return (
    <Grid container>
      {teaGradeData.map((item, index) => (
        <TeaGradeCardContentMobile
          image={item.image}
          heading={item.heading}
          description={item.description}
          description2={item.description2}
          description3={item.description3}
          key={index}
        />
      ))}
    </Grid>
  )
}

