import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CustomHeading from '../CustomHeading'
import CustomImage from '../Images/CustomImage'
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: { paddingLeft: '50px', paddingTop: '28px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '28px' }
  },
  title: {
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.secondary.main,
    fontSize: '10px',
    paddingTop: '8.1px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: { fontSize: '16px', paddingTop: '20px' },
    [theme.breakpoints.up('lg')]: { fontSize: '28px', paddingTop: '32px' }
  },
  imageStyle: { [theme.breakpoints.up('md')]: { justifyContent: "flex-end" } }
}))
export default ({ image, size }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={6} className={classes.root}>
      <CustomImage className={classes.imageStyle}>
        {image}
      </CustomImage>
      <CustomHeading heading={size} className={classes.title} />
    </Grid>
  )
}