import React from 'react'
import { makeStyles } from '@material-ui/core'
import CTCMix from '../../assets/images/ctcMix.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '116px',
    height: '61px',
    [theme.breakpoints.up('md')]: {
      width: '195.8px',
      height: '112.8px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '191.2px',
      height: '107.3px',
      paddingTop: '17px'
    }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={CTCMix} alt="leaf" className={classes.imageStyle} />
}

