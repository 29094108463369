import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
  listItemStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: "bold",
    lineHeight: "24px",
    textAlign: 'left',
    color: theme.palette.common.black,
    paddingRight: '50px',
    cursor: 'pointer'
  }
}))

export default ({ item, to }) => {
  const classes = useStyles()
  return <Link activeClass="active" to={to} spy={true} smooth={true} duration={900} className={classes.listItemStyle}> {item} </Link>
}