import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import ContactDetails from '../components/ContactDetails'
import ContactFormDetails from '../components/ContactFormDetails'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: '0px 10px',
    [theme.breakpoints.up('md')]: { padding: '0px 44px' },
    [theme.breakpoints.up('lg')]: { padding: '0px 72px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} id="contact-us">
      <Grid item xs={6}>
        <ContactDetails />
      </Grid>
      <Grid item xs={6}>
        <ContactFormDetails />
      </Grid>
    </Grid>
  )
}
