import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '18px',
    fontFamily: theme.typography.body1.fontFamily,
    [theme.breakpoints.up('md')]: { fontSize: '12px' },
    [theme.breakpoints.up('lg')]: { fontSize: '16px' }
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: '8px',
    fontWeight: 'normal',
    lineHeight: '12px',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('md')]: { fontSize: '10px', fontWeight: 'normal' },
    [theme.breakpoints.up('lg')]: { fontSize: '14px', lineHeight: '26px' }
  },
  padding: {
    paddingTop: '2px',
    [theme.breakpoints.up('md')]: { paddingTop: '2px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '0px' }
  }
}))
export default ({ text, subtitle, paddingTop }) => {
  const classes = useStyles()
  const checkPadding = paddingTop ? `${classes.subTitle} ${classes.padding}` : classes.subTitle
  return <Typography className={subtitle ? checkPadding : classes.title}>{text}</Typography>
}