import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 'bold',
    lineHeight: 1.25,
    textAlign: 'center',
    color: theme.palette.primary.light,
    fontSize: '15px',
    [theme.breakpoints.up('md')]: { fontSize: '36px' },
    [theme.breakpoints.up('lg')]: { fontSize: '44px' }
  }
}))

export default ({ text }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography className={classes.typographyStyle}>{text}</Typography>
    </React.Fragment>
  )
}


