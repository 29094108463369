import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { ERROR_MESSAGES } from '../../constants/ErrorMessages'
import { sendDistributorDetails } from '../../services/DistributorService'
import CustomButton from '../CustomButton'
import CustomTextField from '../CustomTextField'
import ThankYouDialog from '../ThankYouDialog'
const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '39.6px',
    [theme.breakpoints.up('lg')]: { justifyContent: 'flex-start' }
  }
}))

export default () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [area, setArea] = useState("")
  const [profession, setProfession] = useState("")
  const [error, setError] = useState([])
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = () => {
    let tempError = []
    tempError.name = !name ? ERROR_MESSAGES.NAME : ""
    tempError.email = !email ? ERROR_MESSAGES.EMAIL : ""
    tempError.validEmail = !/(.+)@(.+){2,}\.(.+){2,}/.test(email) ? ERROR_MESSAGES.VALID_EMAIL : ""
    tempError.area = !area ? ERROR_MESSAGES.AREA : ""
    tempError.profession = !profession ? ERROR_MESSAGES.PROFESSION : ""
    setError(tempError)
    if (!tempError.name && !tempError.email && !tempError.area && !tempError.profession && !tempError.validEmail) {
      sendDistributorDetails({ name, email, area, profession }).then(response => {
        if (response.status===201) {
          setName("")
          setEmail("")
          setArea("")
          setProfession("")
          setError("")
          setOpen(true)
        }
      }).catch(error => console.log(error))
    }
  }
  return (
    <Grid container>
      {open ? <ThankYouDialog handleClose={handleClose} open={open} name={name} />:null}
      <Grid item xs={5}>
        <CustomTextField label="Enter Your Name" value={name} handleChange={setName} errorMessage={error && error.name ? error.name : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Email" value={email} handleChange={setEmail} errorMessage={(error && error.email ? error.email : "") || (error && error.validEmail ?error.validEmail :"") } />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Distribution Area" value={area} handleChange={setArea} errorMessage={error && error.area ? error.area : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Current Profession" value={profession} handleChange={setProfession} errorMessage={error && error.profession ? error.profession : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} className={classes.buttonStyle}>
        <CustomButton text="Get in Touch With Us" handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  )
}