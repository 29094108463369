import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import GetInTouch from '../../assets/images/getInTouchButton.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '151px',
    height: '40px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: { width: '157px',height: '44px'},
    [theme.breakpoints.up('lg')]: {width: '266px',height: '66px'}
  }
}))

export default ({ handleSubmit }) => {

  const classes = useStyles()
  return <IconButton onClick={handleSubmit} style={{padding:"0px"}}><img src={GetInTouch} alt="button" className={classes.imageStyle} /></IconButton>
}

