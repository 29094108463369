import React from 'react'
import CustomButton from './CustomButton'
import { Box, Grid, makeStyles } from '@material-ui/core'
import CustomSubtitle from './CustomSubtitle'
import { text } from '../shared/data'

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    [theme.breakpoints.up('md')]: { marginTop: '48px' },
    [theme.breakpoints.up('lg')]: { justifyContent: 'left', marginTop: '68px' }
  },
  subTitle: {
    fontFamily: theme.typography.body2.fontFamily,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: { textAlign: 'center', paddingTop: '0px' },
    [theme.breakpoints.up('lg')]: { textAlign: 'left' }
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <Grid item lg={5}>
      <CustomSubtitle description={text} className={classes.subTitle} />
      <Box className={classes.buttonStyle}>
        <CustomButton text="Contact Now For Your Tea Bag" contact />
      </Box>
    </Grid>
  )
}


