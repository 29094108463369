import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { teaGradeData } from '../shared/data'
import TeaGradeCardContent from './TeaGradeCardContent'
const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', justifyContent: 'space-around', margin: '107px 0px 0px 0px' }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {teaGradeData.map((item, index) => (
        <Box key={index}>
          <TeaGradeCardContent image={item.image} heading={item.heading} description={item.description} description2={item.description2} description3={item.description3} />
        </Box>
      ))}
    </Box>
  )
}
