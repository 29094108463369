export const ERROR_MESSAGES = {
  NAME: 'Please Enter Name',
  PHONE: 'Please Enter Phone No.',
  EMAIL: 'Please Enter Email Id',
  VALID_EMAIL: 'Please Enter Valid Email',
  LOCATION: 'Please Enter Location',
  AREA: 'Please Enter Area',
  PROFESSION: 'Please Enter Profession',
  MESSAGE: 'Please Enter Message'
}
