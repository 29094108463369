import React from 'react'
import TeaDescription from './TeaDescription'
import { Box, Grid, Hidden, makeStyles } from '@material-ui/core'
import Heading from './Heading'
import TeaImage from './Images/TeaImage'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: { paddingTop: '32px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '30px' }
  }
}))
export default (props) => {
  const { heading1, heading2, description1, description2, image, size } = props
  const classes = useStyles()
  return (
    <Box>
      <Heading text="Explore Our Tea" />
      <Grid container className={classes.root}>
        <Hidden mdUp>
          <TeaImage image={image} size={size} />
        </Hidden>
        <TeaDescription heading1={heading1} heading2={heading2} description1={description1} description2={description2} />
        <Hidden smDown>
          <TeaImage image={image} size={size} />
        </Hidden>
      </Grid>
    </Box>
  )
}
