import { Box, Hidden, makeStyles } from '@material-ui/core'
import React from 'react'
import { listItems } from '../shared/data'
import MenuIcon from './MenuIcon'
import NavbarListItem from './NavbarListItem'
const useStyles = makeStyles((theme) => ({
  listStyle: {
    display: 'flex', alignItems: 'center',
    [theme.breakpoints.up('lg')]: { marginRight: '19px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Box className={classes.listStyle}>
        <Hidden mdDown>
          {listItems.map((list, index) => (
            <NavbarListItem key={index} item={list.item} to={list.to} />
          ))}
        </Hidden>
        <Hidden lgUp>
          <MenuIcon />
        </Hidden>
      </Box>
    </React.Fragment>
  )
}

