import React from 'react'
import { makeStyles } from '@material-ui/core'
import Experience from '../../assets/images/Experience.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '42.2px',
    height: '66px',
    [theme.breakpoints.up('md')]: { width: '44.3px', height: '70px' },
    [theme.breakpoints.up('lg')]: { width: '75.9px', height: '120px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Experience} alt="experience" className={classes.imageStyle} />
}