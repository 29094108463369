import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import TeaCard from '../components/TeaCard'
import Slider from 'react-slick'
import { settings, TeaData } from '../shared/data'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.light,
    margin: '34px 0px',
    padding: '34px 25px',
    [theme.breakpoints.up('md')]: { padding: '48px 25px 56px 25px', margin: '48px 0px' },
    [theme.breakpoints.up('lg')]: { padding: '120px 72px 80px 72px', margin: '120px 0px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root} id="products">
      <Slider {...settings}>
        {TeaData.map((item, index) => (
          <TeaCard
            key={index}
            heading1={item.heading1}
            heading2={item.heading2}
            description1={item.description1}
            description2={item.description2}
            image={item.image}
            size={item.size}
          />
        ))}
      </Slider>
    </Box>
  )
}
