import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import FullWidthButton from './FullWidthButton'
import Tagline from './Tagline'
const useStyles = makeStyles((theme) => ({
  tagLine: {
    padding: '99.1px 0px 0px 10px',
    [theme.breakpoints.up('md')]: { padding: '246px 0px 0px 22px' },
    [theme.breakpoints.up('lg')]: { padding: '426px 0px 0px 72px' }
  },
  buttonStyle: {
    marginTop: '12px',
    [theme.breakpoints.up('md')]: { marginTop: '34px' },
    [theme.breakpoints.up('lg')]: { marginTop: '58px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={7} className={classes.tagLine}>
        <Tagline />
        <div className={classes.buttonStyle}>
          <FullWidthButton text="Explore Our Tea" />
        </div>
      </Grid>
    </Grid>
  )
}
