import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Heading from '../components/Heading'
import { franchiseDescription } from '../shared/data'
import FranchiseCard from '../components/FranchiseCard'
import Video from '../components/Images/Video'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.light,
    padding: '34px 10px 34px 10px',
    [theme.breakpoints.up('md')]: { padding: '48px 24px 48px 24px' },
    [theme.breakpoints.up('lg')]: { padding: '120px 72px 120px 72px' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Heading text="Start Your Own Business With Our Franchise" />
      <FranchiseCard image={<Video />} heading="Chai Thikana" description={franchiseDescription} franchise />
    </Box>
  )
}
