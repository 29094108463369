import React from 'react'
import ExperienceImage from '../components/Images/ExperienceImage'
import IndustryImage from '../components/Images/IndustryImage'
import PriceImage from '../components/Images/PriceImage'
import QualityImage from '../components/Images/QualityImage'
import TeaBagImage from '../components/Images/TeaBagImage'
import TeaDustLeafImage from '../components/Images/TeaDustLeafImage'
import TeaPacketImage from '../components/Images/TeaPacketImage'
import CTCMixImage from '../components/Images/CTCMixImage'
import LongLeafImage from '../components/Images/LongLeafImage'
import BigLeafImage from '../components/Images/BigLeafImage'
import MediumLeafImage from '../components/Images/MediumLeafImage'
import DustImage from '../components/Images/DustImage'

export const uspData = [
  {
    image: <ExperienceImage />,
    heading: '48 Years of Experience',
    description: 'Our expertise lies in our experience for being a part of the tea industry since 1972.'
  },
  {
    image: <IndustryImage />,
    heading: 'Expertise In Industry',
    description: 'The top management are professional tea tasters recognized by Government’s Tea board of India.'
  },
  {
    image: <PriceImage />,
    heading: 'Reasonable Price',
    description: 'With our experience and expertise we taste and handpick the best quality tea at reasonable prices.'
  },
  {
    image: <QualityImage />,
    heading: 'Consistent Quality',
    description: 'Our passion for tea motivates us to give you the best taste and consistent quality throughout the year.'
  }
]

export const TeaData = [
  {
    heading1: 'Mahasukh Din Ban Jaye Series',
    heading2: 'Tea Grade - CTC Mix',
    description1:
      'A premium blende of leaf and dust grade straight from the tea estates situated in the foothills of Darjeeling.',
    description2: 'This blende gives a refreshing taste full of flavour, aroma and strongness.',
    image: <TeaPacketImage />,
    size: 'Sizes Available : 250 gm & 500 gm'
  },
  {
    heading1: 'Mahasukh Kadak Dust Chai Series (Red & Yellow)',
    heading2: 'Tea Grade - Dust',
    description1: 'A strong blende of High Grown Dust teas from the tea estates of Upper Assam.',
    description2: 'It is the strongest and is recommended for a strong and refreshing kick.',
    image: <TeaDustLeafImage />,
    size: 'Sizes Available : 500 gm'
  },
  {
    heading1: 'Mahasukh 5kg Wholesale Pack Series',
    heading2: 'Tea Grade - All Grade ',
    description1:
      'We have a wide variety of 5kg wholesale packs for Dust, Family Mixture and CTC grade in all price ranges as required by you.',
    description2: 'Blende of all grades  with high flavour and balanced mixed taste.',
    image: <TeaBagImage />,
    size: 'Sizes Available : 5 kg'
  }
]

export const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  fade: true,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 5000,
  cssEase: 'linear'
}

export const text =
  ' Mahasukh Tea, from the house of Sha Mahasukhlal Thakersi , began its journey from 1972 with the goal to bring garden fresh tea into every household. And the creator of this brand is none other than Mahasukhlal Shah, also known as Mahasukh bhai. Born in Gujarat, Mahasukhbhai started his career selling tea on bicycles. He has laid the foundation of “Mahasukh Tea” and since then we have worked hard to deliver a consistent taste of our tea which is rich in flavor with a long lasting taste on your palate.'

export const teaGradeData = [
  {
    image: <LongLeafImage />,
    heading: 'LONG LEAF',
    description: 'Also known as English',
    description2: 'tea and Orange Pekoe',
    description3: ' tea, is full of aroma.'
  },
  {
    image: <BigLeafImage />,
    heading: 'BIG LEAF',
    description: 'Also known as BOP, is',
    description2: 'high in flavour and will',
    description3: 'tickle your sweet tooth.'
  },
  {
    image: <MediumLeafImage />,
    heading: 'MEDIUM LEAF',
    description: 'This tea is stronger ',
    description2: 'and smaller than the Big',
    description3: 'leaf.'
  },
  {
    image: <DustImage />,
    heading: 'DUST',
    description: 'Strongest and is',
    description2: 'recommended for a',
    description3: 'refreshing kick.'
  },
  {
    image: <CTCMixImage />,
    heading: 'CTC MIX',
    description: 'Blend of all grades,',
    description2: 'high flavour and strong',
    description3: 'aroma.'
  }
]

export const teaGradeDataTablet1 = [
  {
    image: <LongLeafImage />,
    heading: 'LONG LEAF',
    description: 'Also known as English',
    description2: 'tea and Orange Pekoe',
    description3: ' tea, is full of aroma.'
  },
  {
    image: <BigLeafImage />,
    heading: 'BIG LEAF',
    description: 'Also known as BOP, is',
    description2: 'high in flavour and will',
    description3: 'tickle your sweet tooth.'
  },
  {
    image: <MediumLeafImage />,
    heading: 'MEDIUM LEAF',
    description: 'This tea is stronger ',
    description2: 'and smaller than the Big',
    description3: 'leaf.'
  }
]

export const teaGradeDataTablet2 = [
  {
    image: <DustImage />,
    heading: 'DUST',
    description: 'Strongest and is',
    description2: 'recommended for a',
    description3: 'refreshing kick.'
  },
  {
    image: <CTCMixImage />,
    heading: 'CTC MIX',
    description: 'Blend of all grades,',
    description2: 'high flavour and strong',
    description3: 'aroma.'
  }
]

export const distributordescription =
  ' Tea being the most consumed drink in India, presents a huge opportunity to become our distributor or to add Mahasukh tea in your already existing distribution business. We have a competitive edge with  attractive margin and a diversified product portfolio to cater the household, commercial and wholesale use of tea with best quality at reasonable prices.'

export const franchiseDescription =
  'A retail chain brand from the house of Mahasukh tea to tap the fast growing retail opportunities in tea business. Our business model is such that we guarantee maximum profit with minimum investment !'

export const contactDetails = [
  {
    title: 'Address',
    subtitle1: 'Mahasukh Tea, 389 Marketyard',
    subtitle2: 'Gate No: 4, Pune - 411037',
    to:
      'https://www.google.com/maps/dir/28.620491,77.0679584/Mahasukh+Tea,+389+Marketyard+Gate+No:+4,+Pune+-+411037/@23.4874849,71.6931691,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bc2c17a1ff13869:0x87c8fccbe1839821!2m2!1d73.868461!2d18.486773'
  },
  { title: 'Hours Open', subtitle1: 'Monday - Saturday', subtitle2: '10:00 AM - 08:00 PM' },
  { title: 'Phone', subtitle1: '020-24268579', subtitle2: '91-8080954478' }
]

export const listItems = [
  { item: 'Why Us', to: 'why-us' },
  { item: 'Our Products', to: 'products' },
  { item: 'Our Journey', to: 'our-journey' },
  { item: 'Distributor / Franchise', to: 'distributor' },
  { item: 'Contact Us', to: 'contact-us' }
]
