import React from 'react'
import { Box, Hidden, makeStyles } from '@material-ui/core'
import Heading from '../components/Heading'
import TeaGradeCard from '../components/TeaGradeCard'
import TeaGradeCardTablet from '../components/TeaGradeCardTablet'
import TeaGradeCardMobile from '../components/TeaGradeCardMobile'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.light,
    marginTop: '34px',
    padding: '34px 10px',
    [theme.breakpoints.up('md')]: { padding: '48px 24px 56px 24px', marginTop: '48px' },
    [theme.breakpoints.up('lg')]: { padding: '120px 0px 120px 7px', marginTop: '120px' }
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Heading text="Let's Dig Into Tea Grades" />
      <Hidden mdDown>
        <TeaGradeCard />
      </Hidden>
      <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
        <TeaGradeCardTablet />
      </Box>
      <Hidden mdUp>
        <TeaGradeCardMobile />
      </Hidden>
    </Box>
  )
}
