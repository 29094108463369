import React from 'react'
import { makeStyles } from '@material-ui/core'
import CustomHeading from './CustomHeading'
import CustomSubtitle from './CustomSubtitle'
import CustomImage from './Images/CustomImage'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'left', fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: { fontSize: '20px', paddingTop: '15px' },
    [theme.breakpoints.up('lg')]: { fontSize: '32px', paddingTop: '24.7px' }
  },
  subTitle: {
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.secondary.main,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: { lineHeight: '24px', fontSize: '16px' },
    [theme.breakpoints.up('lg')]: { fontSize: '20px', lineHeight: '30px' }
  }
}))
export default ({ image, heading, description, description2, description3 }) => {
  const classes = useStyles()
  return (
    <>
      <CustomImage>{image}</CustomImage>
      <CustomHeading heading={heading} className={classes.title} />
      <CustomSubtitle description={description} className={classes.subTitle} />
      <CustomSubtitle description={description2} p0 className={classes.subTitle} />
      <CustomSubtitle description={description3} p0 className={classes.subTitle} />
    </>
  )
}