import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import ErrorIcon from './ErrorIcon'
const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: '6px',
    [theme.breakpoints.up('md')]: { paddingTop: '5px' },
    [theme.breakpoints.up('lg')]: { paddingTop: '8px' }
  },
  message: {
    fontSize: '10px', lineHeight: '15px', color: theme.palette.error.dark,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('md')]: { fontSize: '12px', lineHeight: '18px' },
    [theme.breakpoints.up('lg')]: { fontSize: '14px', lineHeight: '24px' }
  },
  errorIcon: {
    color: theme.palette.error.dark,
    width: '14.6px',
    height: '14.6px',
    paddingRight: '6.6px',
    [theme.breakpoints.up('lg')]: { width: '19.6px', height: '19.6px', padding: ' 2px 8.4px 0px 0px', }
  },
  contactError: { color: theme.palette.error.main, }
}))
export default ({ message, contactError }) => {
  const classes = useStyles()
  const textStyle = contactError ? `${classes.message} ${classes.contactError}` : classes.message
  return (
    <Box display="flex" className={classes.main}>
      { !message ? "" : <ErrorIcon contactError={contactError} />}
      <Typography className={textStyle}>{message}</Typography>
    </Box>
  )
}
