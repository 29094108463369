import React from 'react'
import { makeStyles } from '@material-ui/core'
import HamburgerIcon from '../assets/images/MenuIcon.png'
const useStyles = makeStyles((theme) => ({
  menuIconStyle: {
    width: '14.6px', height: '11px', paddingRight: '10px',
    [theme.breakpoints.up('md')]: { width: '22px', height: '17.8px', paddingRight: '24.6px' },
    [theme.breakpoints.up('lg')]: { display: 'none' }
  }
}))
export default () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <img src={HamburgerIcon} alt='menuIcon' className={classes.menuIconStyle} />
    </React.Fragment>
  )
}

