import React from 'react'
import { makeStyles } from '@material-ui/core'
import Quality from '../../assets/images/Quality.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '43.7px',
    height: '57.7px',
    [theme.breakpoints.up('md')]: { width: '53px', height: '70px' },
    [theme.breakpoints.up('lg')]: { width: '90.7px', height: '119.8px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Quality} alt="quality" className={classes.imageStyle} />
}