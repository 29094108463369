import React from 'react'
import TeaBag from '../../assets/images/tea1.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

  imageStyle: {
    width: '183px',
    height: '182px',
    [theme.breakpoints.up('md')]: { width: '238px', height: '237px' },
    [theme.breakpoints.up('lg')]: { width: '436px', height: '425px' }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={TeaBag} alt="tea bag" className={classes.imageStyle} />
}