import React, { useState } from 'react'
import {  Grid, makeStyles } from '@material-ui/core'
import { ERROR_MESSAGES } from '../../constants/ErrorMessages'
import { sendBusinessDetails } from '../../services/BusinessService'
import CustomButton from '../CustomButton'
import CustomTextField from '../CustomTextField'
import ThankYouDialog from '../ThankYouDialog'
const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    display: 'flex', justifyContent: 'center', marginTop: '39.6px',
    [theme.breakpoints.up('lg')]: { justifyContent: 'flex-start' }
  }
}))
export default () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [location, setLocation] = useState("")
  const [error, setError] = useState([])
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = () => {
    let tempError = []
    tempError.name = !name ? ERROR_MESSAGES.NAME : ""
    tempError.email = !email ? ERROR_MESSAGES.EMAIL : ""
    tempError.validEmail = !/(.+)@(.+){2,}\.(.+){2,}/.test(email) ? ERROR_MESSAGES.VALID_EMAIL : ""
    tempError.phone = !phone ? ERROR_MESSAGES.PHONE : ""
    tempError.location = !location ? ERROR_MESSAGES.LOCATION : ""
    setError(tempError)
    if (!tempError.name && !tempError.email && !tempError.phone && !tempError.location && !tempError.validEmail) {
      sendBusinessDetails({ name, email, phone, location }).then(response => {
        if (response.status===201) {
          setName("")
          setEmail("")
          setPhone("")
          setLocation("")
          setError("")   
          setOpen(true)
        }
      }).catch(error => console.log(error))
    }
  }

  return (
    <Grid container>
      {open ? <ThankYouDialog open={open} handleClose={handleClose} name={name} />:null}
      <Grid item xs={5}>
        <CustomTextField label="Enter Your Name" value={name} handleChange={setName} errorMessage={error && error.name ? error.name : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Email" value={email} handleChange={setEmail} errorMessage={(error && error.email ? error.email : "") || (error && error.validEmail ?error.validEmail :"") } />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Phone Number" value={phone} handleChange={setPhone} errorMessage={error && error.phone ? error.phone : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CustomTextField label="Enter Location" value={location} handleChange={setLocation} errorMessage={error && error.location ? error.location : ""} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} className={classes.buttonStyle}>
        <CustomButton text="Get in Touch With Us" handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  )
}