import React from 'react'
import DustLeaf from '../../assets/images/tea2.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: { justifyContent: 'flex-end' }
  },
  imageStyle: {
    width: '182px',
    height: '172px',
    [theme.breakpoints.up('md')]: { width: '231px', height: '218px' },
    [theme.breakpoints.up('lg')]: { width: '437px', height: '407px' }
  }
}))
export default () => {
  const classes = useStyles()
  return <img src={DustLeaf} alt="big leaf" className={classes.imageStyle} />
}