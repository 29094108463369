import { makeStyles } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' }
  },
}))
export default ({ children, className }) => {
  const classes = useStyles()
  const checkClassName = className ? `${classes.root} ${className}` : `${classes.root}`
  return (
    <div className={checkClassName}>
      {children}
    </div>
  )
}
