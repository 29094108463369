import React from 'react'
import { makeStyles } from '@material-ui/core'
import FacebookImage from '../../assets/images/facebook.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '6.5px',
    height: '12.8px',
    padding: '4.1px 20.4px 0px 0px',
    [theme.breakpoints.up('md')]: {width: '8.4px', height: '16.6px', padding: '9px 27px 0px 0px',},
    [theme.breakpoints.up('lg')]: { width: '14.2px', height: '28px', padding: '24px 46px 0px 0px', }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={FacebookImage} alt="" className={classes.imageStyle} />
}