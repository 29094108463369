import React from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import Heading from '../components/Heading'
import CupImage from '../components/Images/CupImage.jsx'
import AboutUsContent from '../components/AboutUsContent'
import CustomImage from '../components/Images/CustomImage'
const useStyles = makeStyles((theme) => ({
  content: {
    padding: '26px 10px 0px 10px',
    [theme.breakpoints.up('md')]: { padding: '48px 24px 0px 24px' },
    [theme.breakpoints.up('lg')]: { padding: '90px 72px 0px 72px' }
  },imageStyle:{[theme.breakpoints.up('md')]: { justifyContent:"center" }}
}))
export default () => {
  const classes = useStyles()
  return (
    <div id="our-journey">
      <Heading text="Our Journey" />
      <Grid container className={classes.content}>
        <Hidden lgUp>
          <Grid item xs={12} md={12} lg={6}>
            <CustomImage className={classes.imageStyle}><CupImage /></CustomImage>
          </Grid>
        </Hidden>
        <AboutUsContent />
        <Hidden mdDown>
          <Grid item md={1} />
          <Grid item xs={12} md={12} lg={6}>
            <CustomImage className={classes.imageStyle}><CupImage /></CustomImage>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}