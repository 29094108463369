import React from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import FranchiseContent from './FranchiseContent'
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: { padding: '107px 16px 0px 8px' }
  },
  subTitle: {
    textAlign: 'center', padding: '0px',
    [theme.breakpoints.up('lg')]: { textAlign: 'left', lineHeight: '36px' }
  },
  subTitle2: {
    fontWeight: 'bold', padding: '10px 0px',
    [theme.breakpoints.up('md')]: { padding: '12px 0px 20px 0px' },
    [theme.breakpoints.up('lg')]: { padding: '16px 0px 40.7px 0px' }
  }
}))
export default ({ image, description, heading }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Hidden lgUp>{image}</Hidden>
      <FranchiseContent heading={heading} description={description} />
      <Grid item lg={1} />
      <Hidden mdDown>{image}</Hidden>
    </Grid>
  )
}