import React from 'react'
import { makeStyles } from '@material-ui/core'
import Dust from '../../assets/images/dust.png'

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '116px',
    height: '61px',
    [theme.breakpoints.up('md')]: {
      width: '195.8px',
      height: '112.8px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '185.4px',
      height: '125.3px'
    }
  }
}))

export default () => {
  const classes = useStyles()
  return <img src={Dust} alt="leaf" className={classes.imageStyle} />
}

