import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import CustomHeading from './CustomHeading'
import CustomSubtitle from './CustomSubtitle'
import CustomImage from './Images/CustomImage'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '10px',
    paddingTop: '35px',
    [theme.breakpoints.up('md')]: { paddingLeft: '24px' },
    [theme.breakpoints.up('lg')]: { paddingLeft: '51px' }
  },
  title: {
    [theme.breakpoints.up('md')]: { fontSize: '13px' },
    [theme.breakpoints.up('lg')]: { fontSize: '24px' }
  },
  subTitle: {
    lineHeight: 1.63,
    fontSize: '10px',
    paddingTop: '7px',
    [theme.breakpoints.up('md')]: { fontSize: '11px', paddingTop: '8px', textAlign: 'left' },
    [theme.breakpoints.up('lg')]: { fontSize: '16px' }
  }
}))
export default (props) => {
  const { image, heading, description } = props
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <CustomImage> {image}  </CustomImage>
      <CustomHeading heading={heading} className={classes.title} />
      <CustomSubtitle description={description} className={classes.subTitle} />
    </Box>
  )
}